import SidePanel from "../SidePanel/SidePanel";
import "./Drawer.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import useDrawerOverlay from "app/utils/hooks/useDrawerOverlay";

// eslint-disable-next-line react/prop-types
const Drawer = ({ children, drawerClassName, sidePanelClassName, style, ...props }) => {
	const { isOpen, parentRef } = props;
	useDrawerOverlay(parentRef, isOpen);
	return (
		<SidePanel {...props} className={sidePanelClassName}>
			<div className={classNames(drawerClassName, "drawer")} style={style}>
				{children}
			</div>
		</SidePanel>
	);
};
Drawer.propTypes = {
	parentRef: PropTypes.object,
	isOpen: PropTypes.bool,
};

export default Drawer;
